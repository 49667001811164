import emailjs from "emailjs-com";
import { useFormik } from "formik";
import React from "react";

import {
    ContactFormContainer,
    EmailInput,
    FormButton,
    MessageInput,
    NameInput,
} from "./contactFormStyles";
import { validationSchema } from "./contactFormValSchema";

const ContactForm: React.FC = () => {
    const formik = useFormik({
        initialValues: {
            Name: "",
            Email: "",
            Message: "",
        },
        validationSchema,
        onSubmit: (values) => {
            emailjs
                .send(
                    "service_vinfdyd",
                    "template_dxui4p2",
                    {
                        name: values.Name,
                        email: values.Email,
                        message: values.Message,
                    },
                    "tCvfBYLpwJxcjF0Xh"
                )
                .then(
                    (response) => {
                        alert("Wiadomość została wysłana!");
                        console.log("EmailJS Response:", response);
                    },
                    (error) => {
                        alert("Wystąpił błąd podczas wysyłania wiadomości.");
                        console.error("EmailJS Error:", error);
                    }
                );
        },
    });

    return (
        <ContactFormContainer onSubmit={formik.handleSubmit}>
            <NameInput
                type="text"
                name="Name"
                value={formik.values.Name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Wpisz swoje imię"
            />
            {formik.touched.Name && formik.errors.Name && (
                <div style={{ color: "red", fontSize: "1.3rem" }}>{formik.errors.Name}</div>
            )}

            <EmailInput
                type="email"
                name="Email"
                value={formik.values.Email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Wpisz swój email"
            />
            {formik.touched.Email && formik.errors.Email && (
                <div style={{ color: "red", fontSize: "1.3rem" }}>{formik.errors.Email}</div>
            )}

            <MessageInput
                name="Message"
                value={formik.values.Message}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Wpisz wiadomość"
            />
            {formik.touched.Message && formik.errors.Message && (
                <div style={{ color: "red", fontSize: "1.3rem" }}>{formik.errors.Message}</div>
            )}

            <FormButton type="submit">Wyślij</FormButton>
        </ContactFormContainer>
    );
};

export default ContactForm;
