import styled from 'styled-components';

export const LayoutContainer = styled.div`
  display: grid;
  grid-template-areas:
    "header"
    "main"
    "footer";
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr; 
  width: 100;
  min-height: 100vh;
  overflow-x: hidden;
`;


