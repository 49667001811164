import { ReactComponent as Krat } from '../../../shared/icons/blinds-raised_7857542.svg';
import { ReactComponent as Murarskie } from '../../../shared/icons/brick.svg';
import { ReactComponent as Proj } from '../../../shared/icons/blueprint-svgrepo-com.svg';
import { ReactComponent as Ciesl } from '../../../shared/icons/carpenter-svgrepo-com.svg';
import { ReactComponent as CentralHeating } from '../../../shared/icons/central-heating.svg';
import { ReactComponent as Drzwi } from '../../../shared/icons/door-svgrepo-com.svg';
import { ReactComponent as HeatStation } from '../../../shared/icons/heat-station.svg';
import { ReactComponent as HeatSystem } from '../../../shared/icons/heat-system.svg';
import { ReactComponent as Ele } from '../../../shared/icons/house-svgrepo-com.svg';
import { ReactComponent as HousesRenov } from '../../../shared/icons/houses-renovation.svg';
import { ReactComponent as Dora } from '../../../shared/icons/search-house-svgrepo-com.svg';
import { ReactComponent as Mal } from '../../../shared/icons/painting-roller-outline-svgrepo-com.svg';
import { ReactComponent as Pos } from '../../../shared/icons/parquet-floor-svgrepo-com.svg';
import { ReactComponent as Nadz } from '../../../shared/icons/project-manager-svgrepo-com.svg';
import { ReactComponent as RoofTile } from '../../../shared/icons/roof-tile.svg';
import { ReactComponent as Serw } from '../../../shared/icons/service.svg';
import { ReactComponent as Witr } from '../../../shared/icons/shop-svgrepo-com.svg';
import { ReactComponent as Kamien } from '../../../shared/icons/stones-floor-svgrepo-com.svg';
import { ReactComponent as Mat } from '../../../shared/icons/boxes-box-svgrepo-com.svg';
import { ReactComponent as Glaz } from '../../../shared/icons/tiles-svgrepo-com.svg';
import { ReactComponent as Tynk } from '../../../shared/icons/trowel-svgrepo-com.svg';
import { ReactComponent as Trans } from '../../../shared/icons/delivery-truck-box-delivery-svgrepo-com.svg';
import { ReactComponent as Went } from '../../../shared/icons/ventilation-fan-blower-svgrepo-com.svg';
import { ReactComponent as WaterPipeOut } from '../../../shared/icons/water-pipe-out.svg';
import { ReactComponent as WaterPipeIn } from '../../../shared/icons/water-supply.svg';
import { ReactComponent as Okna } from '../../../shared/icons/window-svgrepo-com.svg';
import { ReactComponent as Stol } from '../../../shared/icons/woodHouse.svg';

import { ServiceProps } from './serviceTypes';

//Prace ogólnobudowlane
export const housesRenovation: ServiceProps = {
    serviceIcon: <HousesRenov width="60" height="60" />,
    serviceTitle: "Prace remontowe",
};

export const roofTiles: ServiceProps = {
    serviceIcon: <RoofTile width="60" height="60" />,
    serviceTitle: "Prace dekarskie",
};

export const brickwork: ServiceProps = {
    serviceIcon: <Murarskie width="60" height="60" />,
    serviceTitle: "Usługi murarskie",
};

export const ciesielskie: ServiceProps = {
    serviceIcon: <Ciesl width="60" height="60" />,
    serviceTitle: "Usługi ciesielskie",
};

export const kamieniarskie: ServiceProps = {
    serviceIcon: <Kamien width="60" height="60" />,
    serviceTitle: "Usługi kamieniarskie",
};

export const glazurnicze: ServiceProps = {
    serviceIcon: <Glaz width="60" height="60" />,
    serviceTitle: "Usługi glazurnicze",
};

export const malarskie: ServiceProps = {
    serviceIcon: <Mal width="60" height="60" />,
    serviceTitle: "Usługi malarskie",
};

export const tynkarskie: ServiceProps = {
    serviceIcon: <Tynk width="60" height="60" />,
    serviceTitle: "Usługi tynkarskie",
};

export const stolarskie: ServiceProps = {
    serviceIcon: <Stol width="60" height="60" />,
    serviceTitle: "Usługi stolarskie",
};

export const elewacje: ServiceProps = {
    serviceIcon: <Ele width="60" height="60" />,
    serviceTitle: "wykonywanie elewacji",
};

export const posadzki: ServiceProps = {
    serviceIcon: <Pos width="60" height="60" />,
    serviceTitle: "wykonywanie posadzek",
};

export const witryny: ServiceProps = {
    serviceIcon: <Witr width="60" height="60" />,
    serviceTitle: "wykonywanie witryn",
};

export const rolowane: ServiceProps = {
    serviceIcon: <Krat width="60" height="60" />,
    serviceTitle: "wykonywanie krat rolowanych",
};

export const okna: ServiceProps = {
    serviceIcon: <Okna width="60" height="60" />,
    serviceTitle: "wykonywanie okien",
};

export const drzwi: ServiceProps = {
    serviceIcon: <Drzwi width="60" height="60" />,
    serviceTitle: "wykonywanie drzwi",
};




// PRACE INSTALACYJNE


export const hydraulicInstalations: ServiceProps = {
    serviceIcon: <WaterPipeIn width="60" height="60" />,
    serviceTitle: "Hydrauliczne",
};

export const hydraulicInstalationsOut: ServiceProps = {
    serviceIcon: <WaterPipeOut width="60" height="60" />,
    serviceTitle: "Hydrauliczne zewnętrzne",
};

export const centralHeating: ServiceProps = {
    serviceIcon: <CentralHeating width="60" height="60" />,
    serviceTitle: "Centralnego ogrzewania",
};

export const heatingStations: ServiceProps = {
    serviceIcon: <HeatStation width="60" height="60" />,
    serviceTitle: "Węzłów cieplnych",
};

export const heatingSystems: ServiceProps = {
    serviceIcon: <HeatSystem width="60" height="60" />,
    serviceTitle: "Zewnętrznych sieci cieplnych",
};

export const wentyl: ServiceProps = {
    serviceIcon: <Went width="60" height="60" />,
    serviceTitle: "Wentylacji",
};


//Pozostałe usługi
export const nadzor: ServiceProps = {
    serviceIcon: <Nadz width="60" height="60" />,
    serviceTitle: "Nadzór budowlany",
};
export const doradztwo: ServiceProps = {
    serviceIcon: <Dora width="60" height="60" />,
    serviceTitle: "Doradztwo budowlane",
};
export const gwarancja: ServiceProps = {
    serviceIcon: <Serw width="60" height="60" />,
    serviceTitle: "Serwis gwarancyjny i pogwarancyjny",
};
export const transport: ServiceProps = {
    serviceIcon: <Trans width="60" height="60" />,
    serviceTitle: "Kompleksowa obsługa transportowa",
};
export const zaopatrzenie: ServiceProps = {
    serviceIcon: <Mat width="60" height="60" />,
    serviceTitle: "Zaopatrzenie w materiały budowlane",
};
export const proj: ServiceProps = {
    serviceIcon: <Proj width="60" height="60" />,
    serviceTitle: "Usługi projektowe",
};
