import { styled } from "styled-components";

export const Underline = styled.div`
    display: flex;
    justify-self: center;
    height: 0.01rem;
    background-color: turquoise;
    width: 20%;
    margin-top: 1rem;
`;

export const ServiceContainer = styled.div`
    color: white; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 250px;

    @media (max-width: 567px) {
        height: 150px;
    };
`;

export const ServiceIcon = styled.div`
    display: flex;
    height: 30%;
    justify-content: center;
    align-items: flex-start;
    color: white;

    svg{
        fill: white;
        stroke: white !important;
    }

    @media (max-width: 567px) {
        height: 50%;
    };
`;

export const ServiceHeader = styled.div`
    display: flex;
    font-size: 1.5rem;
    font-family: var(--titles-font);
    font-weight: bolder;
    text-align: center;
    @media (max-width: 768px) {
        font-size: 0.7rem;
    };
    
    @media (max-width: 540px) {
        font-size: 0.8rem;
    };
`;