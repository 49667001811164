import React from 'react';

import { MainContentContainer } from './mainStyles';
import { MainContentProps } from './mainTypes';

const MainContent: React.FC<MainContentProps> = ({ children }) => {
    return (
        <MainContentContainer>
            {children}
        </MainContentContainer >
    )
};

export default MainContent;