import React from 'react';

import Footer from './footer/Footer';
import Header from './header/Header';
import { LayoutContainer } from './layoutStyles';
import { LayoutProps } from './layoutTypes';
import MainContent from './mainContent/MainContent';

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <LayoutContainer>
      <Header />
      <MainContent>
        {children}
      </MainContent>
      <Footer />
    </LayoutContainer>
  );
};

export default Layout;