import { styled } from "styled-components";

import { ReactComponent as CloseBtnSvg } from '../../../shared/icons/closeBtn.svg';
import { ReactComponent as LeftArrowSvg } from '../../../shared/icons/leftArr.svg';
import { ReactComponent as RightArrowSvg } from '../../../shared/icons/rightArr.svg';

export const GalleryWrapper = styled.div`
        padding-top: 2rem;
        overflow: hidden;
`;

export const PicturesContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)); 
    max-width: 1400px;
    margin: 0 auto;
    box-sizing: content-box;
    gap: 3rem;
    padding: 4rem;

    @media (max-width: 540px) {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); 
    };
`;

export const Picture = styled.img`
    width: 100%;
    height: 430px;
    transition: transform 0.3s ease; 
    border: solid 1.5px rgb(200, 150, 100);;
    cursor: pointer;
    box-shadow: 0px 0px 10px rgba(251, 192, 147, 1);

    &:hover{
        transform: scale(1.1);
    }

    
`;

export const Modal = styled.div`
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.85);
`;

export const LeftArrow = styled(LeftArrowSvg)`
    cursor: pointer;
    width: 12vw;
    height: 100%;
    fill: grey;

    &:hover{
        fill: white;
    }

    &:active{
        fill: grey;
    }
`;

export const RightArrow = styled(RightArrowSvg)`
    cursor: pointer;
    width: 12vw;
    height: 100%;
    fill: grey;

    &:hover{
        fill: white;
    }

    &:active{
        fill: grey;
    }
`;

export const CloseBtn = styled(CloseBtnSvg)`
    cursor: pointer;
    width: 5%;
    height: 10%;
    position: absolute;
    right: 4%;
    top: 7%;
    z-index: 2;
    box-sizing: content-box;
    fill: grey;

    &:hover{
       fill: white;
    }

    &:active{
        fill: grey;
    }
`;

export const ModalContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    margin: 3%;
    background: linear-gradient(135deg, black, #00aaa4);
    border: solid 1px white;
`;

export const PictureInModal = styled.img`
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    object-fit: contain;
    user-select: none;
`;



