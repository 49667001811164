import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    Name: Yup.string()
      .matches(/^[a-zA-Z\s]*$/, "Imię nie może zawierać cyfr ani znaków specjalnych")
      .required("Imię jest wymagane"),
    Email: Yup.string()
      .email("Nieprawidłowy format adresu email")
      .required("Email jest wymagany"),
    Message: Yup.string().required("Wiadomość jest wymagana"), 
  });