import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  :root {
    --color-primary: #0070f3;
    --color-background: #ffffff;
    --color-text: #333333;
    --spacing: 1rem;
    --background-gradient: linear-gradient(135deg, #0630d6, rgba(167, 139, 250, 1));
    --titles-font: 'AntoniaH1';
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: Arial, sans-serif;
    background: white;
    color: var(--color-text);
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body::-webkit-scrollbar {
    width: 10px; 
  }

  body::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5); 
    border-radius: 5px; 
    border: 2px solid transparent; 
  }

  body::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1); 
  }

  body {
    scrollbar-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.1);
    scrollbar-width: thin;
  }

  .grid-container {
    display: grid;
    gap: var(--spacing);
  }
`;
