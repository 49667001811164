
import { Address, Contact, ContactPart, CopyrightWrapper, DetailsContactWrapper, DetailsPart, EmailIcn, FooterContainer, FooterWrapper, LocationIcn, PhoneIcn, ShortName, TittleSep } from "./footerStyles";

const Footer: React.FC = () => {
    return (
        <FooterContainer>
            <FooterWrapper>
                <h2>CHEMIDEX SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ</h2>
                <ShortName>
                    <h2>Chemidex sp. z o.o.</h2>
                </ShortName>

                <TittleSep></TittleSep>

                <DetailsContactWrapper>
                    <DetailsPart>
                        <h3>Dane firmy:</h3>
                        <p>NIP: 5372682263</p>
                        <p>REGON: 528683728</p>
                        <p>KRS: 0001106542</p>
                        <p>NIP: 537-268-22-63</p>
                    </DetailsPart>
                    <ContactPart>
                        <h3>Dane kontaktowe:</h3>
                        <Address>
                            <LocationIcn></LocationIcn>
                            <p>ul. Dębowa 11, 03-053 Warszawa</p>
                        </Address>
                        <Contact>
                            <EmailIcn></EmailIcn>
                            <p>kontakt@chemidex.com.pl</p>
                        </Contact>
                        <Contact>
                            <PhoneIcn></PhoneIcn>
                            <p>+48 884 213 788</p>
                        </Contact>
                    </ContactPart>
                </DetailsContactWrapper>

                <CopyrightWrapper>
                    <p>
                        Copyright © Chemidex sp. z o.o. 2024
                    </p>
                </CopyrightWrapper>
            </FooterWrapper>

        </FooterContainer>
    );
};

export default Footer;