import ContactForm from "./components/contactForm/ContactForm";
import Gallery from "./components/Gallery";
import Service from "./components/Service";
import { brickwork, centralHeating, ciesielskie, doradztwo, drzwi, elewacje, glazurnicze, gwarancja, heatingStations, heatingSystems, housesRenovation, hydraulicInstalations, hydraulicInstalationsOut, kamieniarskie, malarskie, nadzor, okna, posadzki, proj, rolowane, roofTiles, stolarskie, transport, tynkarskie, wentyl, witryny, zaopatrzenie } from "./components/servicesDetails";
import { AboutCompanyContainer, AboutCompanyText, AddressPart, AddressPlate, ContactFormWrapper, ContactPart, EmailIcn, LocationContainer, LocationIcn, MapContainer, PhoneIcn, PicturesContainer, SectionTitle, Separator, ServicesContainer, ServicesType, ServicesWrapper, TextBg, TitleContainer, TitleUnderline, WordColor } from "./homePageStyles";
//import { ReactComponent as OrlenLogo } from "../../shared/icons/orlenLogo.svg";

const HomePage: React.FC = () => {
    return (
        <>
            <div id="home"></div>

            <AboutCompanyContainer  >
                <TitleContainer>
                    <h1>Inwestycje remontowe i budowlane</h1>
                </TitleContainer>
            </AboutCompanyContainer>
            <TextBg>
                <AboutCompanyText >
                    <h4>
                        Od 1992 roku specjalizujemy się w pracach remontowych i konserwacyjnych oraz realizacji dużych projektów budowlanych.
                        Kompleksowo wykonujemy zamówienia zgodnie z dostarczonym projektem, obejmując szeroki zakres etapów inwestycji.
                    </h4>
                </AboutCompanyText>
            </TextBg>

            <ServicesWrapper color="linear-gradient(135deg, black, #00aaa4)">
                <ServicesType>
                    <p>
                        USŁUGI OGÓLNOBUDOWLANE I PROJEKTOWE
                    </p>
                </ServicesType>

                <ServicesContainer>
                    <Service {...roofTiles} />
                    <Service {...housesRenovation} />
                    <Service {...brickwork} />
                    <Service {...ciesielskie} />
                    <Service {...kamieniarskie} />
                    <Service {...glazurnicze} />
                    <Service {...malarskie} />
                    <Service {...tynkarskie} />
                    <Service {...stolarskie} />
                    <Service {...elewacje} />
                    <Service {...posadzki} />
                    <Service {...witryny} />
                    <Service {...rolowane} />
                    <Service {...okna} />
                    <Service {...drzwi} />
                </ServicesContainer>
            </ServicesWrapper>

            <ServicesWrapper color="linear-gradient(135deg, black, #00aaa4)">
                <ServicesType>
                    <p>
                        PRACE INSTALACYJNE
                    </p>
                </ServicesType>
                <ServicesContainer>
                    <Service {...hydraulicInstalations} />
                    <Service {...hydraulicInstalationsOut} />
                    <Service {...centralHeating} />
                    <Service {...heatingSystems} />
                    <Service {...heatingStations} />
                    <Service {...wentyl} />
                </ServicesContainer>
            </ServicesWrapper>

            <ServicesWrapper color="linear-gradient(135deg, black, #00aaa4)">
                <ServicesType>
                    <p>
                        POZOSTAŁE USŁUGI
                    </p>
                </ServicesType>

                <ServicesContainer>
                    <Service {...nadzor} />
                    <Service {...doradztwo} />
                    <Service {...gwarancja} />
                    <Service {...transport} />
                    <Service {...zaopatrzenie} />
                    <Service {...proj} />
                    
                </ServicesContainer>

            </ServicesWrapper>

            <Separator></Separator>

            {/* <ReferencesContainer>
                <SectionTitle>
                    <h2><WordColor wordColor="orange">Realizowaliśmy</WordColor> projekty dla</h2>
                </SectionTitle>

                <TitleUnderline color="black"></TitleUnderline>

                <LogoBg>
                    <LogosContainer>
                        <OrlenLogo width="120" height="120" />
                        <OrlenLogo width="120" height="120" />
                        <OrlenLogo width="120" height="120" />
                        <OrlenLogo width="120" height="120" />
                    </LogosContainer>
                </LogoBg>
            </ReferencesContainer> */}
            <div id="gallery"></div>

            <PicturesContainer > 
                <Gallery></Gallery>
            </PicturesContainer>

            <LocationContainer>
                <SectionTitle>
                    <h2>Jak nas <WordColor wordColor="orange">znelźć?</WordColor></h2>
                </SectionTitle>
                <TitleUnderline color="black"></TitleUnderline>

                <MapContainer>
                    <AddressPlate>
                        <h2>Nasze Biuro</h2>
                        <AddressPart>
                            <LocationIcn></LocationIcn>
                            <p>ul. Dębowa 11,<br></br> 03-053 Warszawa</p>
                        </AddressPart>
                        <ContactPart>
                            <EmailIcn></EmailIcn>
                            <p>kontakt@chemidex.com.pl</p>
                        </ContactPart>
                        <ContactPart>
                            <PhoneIcn></PhoneIcn>
                            <p>+48 884 213 788</p>
                        </ContactPart>
                    </AddressPlate>
                    <iframe
                        title="companyLocation"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2934.4559171116857!2d20.95199687695531!3d52.36332064781744!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ec819c3b3843f%3A0x11e540a9e0a45f4a!2sD%C4%99bowa%2011%2C%2003-053%20Warszawa!5e1!3m2!1sen!2spl!4v1732724362098!5m2!1sen!2spl"
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade">
                    </iframe>
                </MapContainer>
            </LocationContainer >

            <ContactFormWrapper id="contact">
                <SectionTitle >
                    <h2>
                        <WordColor wordColor="rgb(200, 150, 100)">Napisz</WordColor>
                        <WordColor wordColor="white"> do nas ✉️</WordColor>
                    </h2>
                </SectionTitle>

                <TitleUnderline color="white"></TitleUnderline>

                <ContactForm></ContactForm>
            </ContactFormWrapper>
        </>
    );
};

export default HomePage;

