import { styled } from "styled-components";

export const ContactFormContainer = styled.form`
    display: flex;
    flex-direction: column;
    max-width: 1400px;
    width: 100%;
    padding: 4rem;
    gap: 1rem;
    align-items: center; 
`;

export const NameInput = styled.input`
    width: 80%;
    padding: 0.5rem;
    background-color: rgba(255,255,255, 0.7);
    outline: none;
    border: solid 3px rgb(200, 150, 100);;    
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
    font-size: 1.4rem;

`;

export const EmailInput = styled.input`
    width: 80%;
    padding: 0.5rem;
    background-color: transparent;
    background-color: rgba(255,255,255, 0.7);
    outline: none;
    border: solid 3px rgb(200, 150, 100);;  
      box-shadow: 0px 0px 10px rgba(255, 165, 0, 0.7);
    font-size: 1.4rem;


`;

export const MessageInput = styled.textarea`
    width: 80%;
    padding: 0.5rem;
     background-color: transparent;
    background-color: rgba(255,255,255, 0.7);
    height: 12rem;
    outline: none;
    border: solid 3px rgb(200, 150, 100);;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
    font-size: 1.4rem;

`;

export const FormButton = styled.button`
    width: 25%;  
    height: 3rem;
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: large;
    align-self: flex-end;
    margin-right: 10%;
    background-color: rgba(255,255,255, 0.7);
    border: solid 5px rgb(200, 150, 100);;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
    color: gray;

    &:hover{
        background-color: rgba(255,255,255, 1);
        color: black;
    }
`;