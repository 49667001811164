import { ServiceContainer, ServiceIcon, ServiceHeader, Underline } from "./serviceStyles";
import { ServiceProps } from './serviceTypes';

const Service: React.FC<ServiceProps> = ({ serviceIcon, serviceTitle }) => {
    return (
        <ServiceContainer>
            <ServiceIcon>{serviceIcon}</ServiceIcon>
            <ServiceHeader><h4>{serviceTitle}</h4></ServiceHeader>
            <Underline></Underline>

        </ServiceContainer>
    );
};

export default Service; 