import { Link } from "react-router-dom";
import { styled } from "styled-components";

import { ReactComponent as HamburgerIconSvg } from '../../shared/icons/HamburgerMenuIcon.svg';

export const HeaderContainer = styled.header<{ ismenuopen: string }>`
  grid-area: header;
  position: fixed;
  width: 100%;
  display: flex;
  padding: 1rem 1rem;
  background: rgb(0 ,0, 0, 0.25);  
  color: white;
  user-select: none;
  border-bottom: 0.3rem solid rgb(200, 150, 100);;
  z-index: 1;
  backdrop-filter: blur(10px); 
  box-shadow: 0 8px 16px rgba(255, 255, 255, 0.3);
  height: 8rem;

  @media (max-width: 768px) {
    border-bottom: ${({ ismenuopen }) => (ismenuopen === 'true' ? 'none' : 'solid 0.05rem rgb(200, 150, 100);')};
    height: 4rem;

  }
`;

export const CenteredHeaderContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px; 
  margin: 0 auto; 

  &>:nth-child(2) {
    margin-left: 10%;
  }

  @media (max-width: 1000px) {
    &>:nth-child(2) {
      margin-left: 0%;
    }
  }
`;

export const Logo = styled(Link)`
  display: flex;
  align-items: center;
  font-size: xx-large;
  padding: 0rem 0rem;
  cursor: pointer;
  color: white;
  text-decoration: none;
`;

export const NavMenu = styled.nav`
  display: flex;
  gap: 2rem;
  font-size: xx-large;
  justify-content: center;

  @media (max-width: 920px) {
    gap: 1rem;

  }
`;

export const NavItem = styled.p`
  text-decoration: none;
  color: white;
  font-weight: 500;

  &:hover {
    cursor: pointer;
    color: rgb(200, 150, 100);;
  }

  &:active {
    color: white; 
  }

    @media (max-width: 1200px) {
      font-size: x-large;

  }
  @media (max-width: 920px) {
      font-size: large;

  }

  @media (max-width: 768px) {
    display: none;
  }

  
  
`;

export const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const IconWrapper = styled.div`
display: none;
cursor: pointer;


  @media (max-width: 768px) {
      display: flex;

     
    }
`

export const HamburgeIcn = styled(HamburgerIconSvg)`
  fill: white;

  &:hover{
    fill:rgb(200, 150, 100);;
  }
`;

export const PhoneNumber = styled.span`
  display: flex;
  font-weight: bold;
  gap: 0.3rem;
  font-size: 1.8rem;

  &:hover{
    user-select: all;
  }

  svg{
    margin-top: 5px;
  }

  @media (max-width: 920px) {
    font-size: 1.2rem;

      svg{
      margin-top: 0px;
    }
  }
`;

export const Email = styled.span`
  display: flex;
  font-size: 1.6rem;
  gap: 0.3rem;

  &:hover{
    user-select: all;
  }
  
  svg{
    margin-top: 5px;
  }

  @media (max-width: 920px) {
    font-size: 1.2rem;

      svg{
      margin-top: 0px;
    }
  }
`;

export const HamburgerMenuWrapper = styled.div`
  display: none;
  justify-content: flex-end;
  align-content: center;
  position: fixed;
  width: 100%;
  
  @media (max-width: 768px) {
    display: flex;
    cursor: pointer;
    margin-top: 4rem;
    z-index: 1;
    backdrop-filter: blur(20px); 
    background-color: transparent;
    box-shadow: 0 8px 16px rgba(255, 255, 255, 0.3);
  }
`
