import { styled } from "styled-components";

import { ReactComponent as LocationSvg } from "../../shared/icons/location.svg";
import { ReactComponent as EmailSvg } from "../../shared/icons/MailIcon.svg";
import { ReactComponent as PhoneSvg } from "../../shared/icons/PhoneIcon.svg";
import AboutPicture from '../../shared/pictures/AboutPicture.jpg';

export const AboutCompanyContainer = styled.main`
    display: flex;
    flex: 1;
    flex-direction: row;
    background-image: url(${AboutPicture});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    justify-content: center;   
    align-items: center; 
    font-weight: bold;
    height: 400px;
    color: white;
    margin-top: 8rem;

    @media (max-width: 768px) {
        height: 300px;
        margin-top: 4rem;
    };

    @media (max-width: 540px) {
        height: 200px;
    };
    
`;

export const TitleContainer = styled.div`
  background: rgb(0,0,0, 0.4);  
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  box-sizing: content-box;
  font-size: 2.7rem;
  font-family: var(--titles-font);
    
  @media (max-width: 1300px) {
    font-size: 2rem;
};

@media (max-width: 1072px) {
    font-size: 1.5rem;
};

  @media (max-width: 810px) {
        font-size: 1.3rem;
};

@media (max-width: 700px) {
        font-size: 1.1rem;
};

@media (max-width: 590px) {
        font-size: 0.9rem;
};

@media (max-width: 483px) {
        font-size: 0.8rem;
};

@media (max-width: 429px) {
        font-size: 0.7rem;
}; 

@media (max-width: 375px) {
        font-size: 0.6rem;
}; 

@media (max-width: 320px) {
        font-size: 0.5rem;
}; 
`;

export const TextBg = styled.div`
    padding: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: center;
    flex-direction: column;
    background-color: white;
    width: 100%;

    @media (max-width: 768px) {
        font-size: medium;
        line-height: 1.2rem;
        padding-left: 1rem;
        margin-left: 1rem;
        padding-right: 1rem;
        margin-right: 1rem;
        align-self: flex-start;
    }
`;


export const AboutCompanyText = styled.div`
    display: flex;
    margin-top: 1rem;
    padding-bottom: 1rem;
    max-width: calc(939px + 2rem);
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2.4rem;
    padding-left: 0.5rem;
    font-family: var(--titles-font);
    color: black;
    
  @media (max-width: 768px) {
        font-size: medium;
        padding-left: 1rem;
        padding-right: 1rem;
        line-height: 1.8rem;

    }

    @media (max-width: 540px) {
        font-size: 0.8rem;
        padding-top: 0rem;
};
`;

export const ServicesWrapper = styled.div<{ color: string }>`
margin-top: 2rem;
background: ${(props) => props.color};    
padding-top: 2rem;
box-shadow: 0px -7px 15px rgba(0, 0, 0, 0.4), 0px 7px 15px rgba(0, 0, 0, 0.4);
border-top: solid 3px rgb(200, 150, 100);;
border-bottom: solid 3px rgb(200, 150, 100);;

`;

export const ServicesType = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    background-color: rgb(200, 150, 100);;
    font-family: var(--titles-font);
    font-size: 2.2rem;
    font-weight: bold;
    height: 5rem;
    box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.5), 0px 5px 10px rgba(0, 0, 0, 0.5);
    border-top: solid 5px white;
    border-bottom: solid 5px white;
    text-align: center;
    
    @media (max-width: 829px) {
        font-size: 1.8rem;
};
@media (max-width: 440px) {
        font-size: 1.5rem;
};

@media (max-width: 370px) {
        font-size: 1.2rem;
};
`;

export const ServicesContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(345px, 1fr)); 
    max-width: 1400px;
    margin: 0 auto;
    padding: 0.5rem;
    box-sizing: content-box;
    gap: 0.5rem;
    padding-top: 2rem;

    @media (max-width: 714px) {
        gap: 2rem;
        grid-template-columns: repeat(auto-fit, minmax(220px, 1fr)); 
        padding: 3rem;

    };

    @media (max-width: 450px) {
        gap: 2rem;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); 
        padding: 3rem;

    };
`;

export const Separator = styled.div`
    display: flex;
    flex: 1;
    height: 0.01rem;
    background-color: white;
`;

export const TitleUnderline = styled.div<{ color: string }>`
    display: flex;
    justify-self: center;
    height: 0.01rem;
    background-color: ${(x) => x.color};
    width: 10%;
`;

export const ReferencesContainer = styled.div`
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const SectionTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
    font-family: var(--titles-font);
    padding-top: 4rem;
    padding-bottom: 2rem;
    color: black;
    
    @media (max-width: 768px) {
    font-size: 1.3rem;
    }

    @media (max-width: 540px) {
    font-size: 0.8rem;
};
`;
export const LogoBg = styled.div`
    width: 100%;
    background-color: red;
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
    height: 5rem;
    margin-top: 2rem;
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
    background: linear-gradient(135deg, rgba(255, 50, 10, 1), rgba(255, 80, 0, 1));
    border-top: solid 10px rgb(200, 150, 100);;
    border-bottom: solid 10px rgb(200, 150, 100);;
    box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.3), 0px 5px 10px rgba(0, 0, 0, 0.3);
`;

export const LogosContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    place-self: center;
    max-width: 1200px;
    height: auto;
    gap: 1rem;
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 3rem;
    padding-bottom: 3rem;

`;

export const PicturesContainer = styled.span`
display: flex;
justify-content: center;
background: linear-gradient(135deg, black, #00aaa4);
margin-top: 2rem;
padding-top: 5rem;
box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.5), 0px 5px 10px rgba(0, 0, 0, 0.5);

border-top: solid 3px rgb(200, 150, 100);;
border-bottom: solid 3px rgb(200, 150, 100);;
`;

export const WordColor = styled.div<{ wordColor: string }>`
    color: ${(props) => props.wordColor};
    display: inline;
`;

export const LocationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    width: 100%;
    height: calc(1000px + 5rem );
    justify-self: center;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 5rem;

  
`;

export const MapContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 1400px;
    margin-top: 3rem; 
    position: relative;    
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);

    bottom: 0;

    iframe{
        display: flex;
        flex: 1;
    }

    @media (max-width: 520px) {
        max-width: 520px;
    };
`;

export const AddressPlate = styled.div`
    display: flex;
    position: absolute;
    width: 420px;
    height: 300px;
    background-color: white;
    margin: 10px;
    flex-direction: column;
    color: black;
    font-size: 1.3rem;
    padding: 2rem;
    box-shadow: 5px 5px 16px rgba(0, 0, 0, 0.8);

    h2{
        padding-bottom: 1rem;
        margin-left: 0.35rem;
    }

    span{
        margin-left: 4rem;
    }

    @media (max-width: 520px) {
        max-width: 90%;
        font-size: 1rem;
        height: 230px;
    };

    @media (max-width: 365px) {
        min-width: 270px;

    };
`;

export const LocationIcn = styled(LocationSvg)`
    stroke: black;
    width: 3.5rem;
    height: 3rem;
    display: inline-block;
    margin-right: 0.5rem;
    padding-bottom: 5px;

    @media (max-width: 520px) {
        width: 2.5rem;
        height: 2rem;
    };
`;

export const PhoneIcn = styled(PhoneSvg)`
    stroke: black;
    width: 3.5rem;
    height: 2.3rem;
    display: inline-block;
    margin-right: 0.5rem;

    @media (max-width: 520px) {
        width: 2.5rem;
        height: 1.7rem;
        margin-top: 0.3rem;

    };
`;

export const AddressPart = styled.div`
    display: inline-block;
    display: flex;
    align-items: flex-start;
    font-size: 1.5rem;
    padding-bottom: 1rem;
    @media (max-width: 520px) {
        font-size: 1rem;
    };
`;

export const ContactPart = styled.div`
    display: inline-block;
    display: flex;
    align-items: flex-start;
    font-size: 1.5rem;
    padding-bottom: 1rem;
    
    @media (max-width: 520px) {
        font-size: 1rem;
    };
`;



export const EmailIcn = styled(EmailSvg)`
    stroke: black;
    width: 3.5rem;
    height: 2.5rem;
    display: inline-block;
    margin-right: 0.5rem;
    margin-top: -3px;

    @media (max-width: 520px) {
        width: 2.5rem;
        height: 1.7rem;
    };
`;

export const ContactFormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  background: linear-gradient(135deg, black, #00aaa4);
  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.5), 0px 5px 10px rgba(0, 0, 0, 0.5);
  border-bottom: solid 1px white;
  flex-direction: column;
`;
