import { useState } from 'react';

import { CloseBtn, GalleryWrapper, LeftArrow, Modal, ModalContent, Picture, PictureInModal, PicturesContainer, RightArrow } from './galleryStyles';

const importAll = (requireContext: __WebpackModuleApi.RequireContext): string[] =>
    requireContext.keys().map((key) => requireContext(key) as string);

const Gallery: React.FC = () => {
    const [currentImage, setCurrentImage] = useState<number | null>(null);

    const openModal = (index: number) => setCurrentImage(index);
    const closeModal = () => setCurrentImage(null);

    const prevImage = () =>
        setCurrentImage((prev) => (prev === 0 ? images.length - 1 : (prev as number) - 1));
    const nextImage = () =>
        setCurrentImage((prev) => (prev === images.length - 1 ? 0 : (prev as number) + 1));

    const images: string[] = importAll(
        require.context('../../../shared/pictures', false, /\.(png|jpe?g|svg)$/)
    );

    
    return (
        <>
            <GalleryWrapper>
                <PicturesContainer>
                    {images.map((image, index) => (
                        <Picture
                            key={index}
                            src={image}
                            alt={`Construction ${index + 1}`}
                            loading="lazy"
                            onClick={() => openModal(index)} >
                        </Picture>
                    ))}
                </PicturesContainer>
            </GalleryWrapper>

            {currentImage !== null && (
                <Modal>
                    <ModalContent>
                        <LeftArrow onClick={() => prevImage()} />
                        <PictureInModal loading="lazy" src={images[currentImage]} alt={`Construction ${currentImage + 1}`} >
                        </PictureInModal>
                        <CloseBtn onClick={() => closeModal()}></CloseBtn>
                        <RightArrow onClick={() => nextImage()} />
                    </ModalContent>
                </Modal>
            )}
        </>
    );
};

export default Gallery; 