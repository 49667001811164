import { useEffect, useRef, useState } from "react";

import { ReactComponent as MailIcon } from '../../shared/icons/MailIcon.svg';
import { ReactComponent as PhoneIcon } from '../../shared/icons/PhoneIcon.svg';

import HamburgerMenu from "./hamburgerMenu/HamburgerMenu";
import { CenteredHeaderContainer, ContactInfo, Email, HamburgeIcn, HamburgerMenuWrapper, HeaderContainer, IconWrapper, Logo, NavItem, NavMenu, PhoneNumber, } from "./headerStyles";
import { scrollToContact, scrollToGallery, scrollToHome } from "./headerUtils";

const Header: React.FC = () => {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const handleMenuState = () => {
        if (!isMenuOpen) {
            openMenu();
        } else {
            closeMenu();
        }
    }

    const openMenu = () => setIsMenuOpen(true);
    const closeMenu = () => setIsMenuOpen(false);

    const menuRef = useRef<HTMLDivElement>(null);
    const menuIconRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                menuRef.current &&
                menuIconRef.current &&
                !menuRef.current.contains(event.target as Node) &&
                !menuIconRef.current.contains(event.target as Node)
            ) {
                closeMenu();
            }
        };

        if (isMenuOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isMenuOpen]);

    return (
        <>
            <HeaderContainer ismenuopen={isMenuOpen.toString()}>
                <CenteredHeaderContainer>
                    <Logo to="/">Chemidex</Logo>

                    <NavMenu>
                        <NavItem onClick={() => scrollToHome()}>Home</NavItem>
                        <NavItem onClick={() => scrollToGallery()}>Galeria</NavItem>
                        <NavItem onClick={() => scrollToContact()}>Kontakt</NavItem>
                    </NavMenu>

                    <ContactInfo>
                        <PhoneNumber>
                            <PhoneIcon width="22" height="22" fill="currentColor" />
                            +48 884 213 788
                        </PhoneNumber>
                        <Email>
                            <MailIcon width="20" height="24" fill="currentColor" />
                            chemidex@chemidex.com.pl
                        </Email>
                    </ContactInfo>

                    <IconWrapper ref={menuIconRef}>
                        <HamburgeIcn width="24" height="24" onClick={() => handleMenuState()} />
                    </IconWrapper>
                </CenteredHeaderContainer>
            </HeaderContainer>

            <HamburgerMenuWrapper ref={menuRef}>
                <HamburgerMenu isMenuOpen={isMenuOpen} />
            </HamburgerMenuWrapper>
        </>
    );
};

export default Header;