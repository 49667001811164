import { Link } from "react-router-dom";
import { styled } from "styled-components";

export const NavMobMenu = styled.nav<{ ismenuopen: string }>`
  display: ${({ ismenuopen }) => (ismenuopen === 'true' ? 'flex' : 'none')};
  flex-direction: column;
  background-color: transparent;
  border-bottom: solid 0.05rem rgb(200, 150, 100);;
  width: 100%;
  align-items: center;
  gap: 0.6rem;
  padding-bottom: 0.6rem;
  `;

export const NavMobItem = styled.p`
  text-decoration: none;
  color: white;
  font-weight: 500;

  &:hover {
    cursor: pointer;
    color: rgb(200, 150, 100);;

    &:active {
      color: white;

    }
  }
`;

export const Separator = styled.div`
  width: 100%;          
  height: 0.01rem;           
  background-color: rgb(200, 150, 100);; 
  opacity: 0.5;  
`;